import { useCallback, useState } from 'react'

import useBoolean from '~/utils/hooks/useBoolean'

const useCustom = () => {
  const [selectedMenu, setSelectedMenu] = useState('')
  const { value: openMenubarContent, toggle: toggleOpenMenubarContent } = useBoolean()

  const handleSelectMenu = useCallback(
    (path) => () => {
      if (path) {
        toggleOpenMenubarContent()
      }
      setSelectedMenu(path)
    },
    [],
  )

  return {
    data: {
      openMenubarContent,
      selectedMenu,
    },
    methods: {
      handleSelectMenu,
    },
  }
}

export default useCustom
