/* eslint-disable sort-keys */
const palette = {
  blue: {
    25: '#ecf8ff',
    50: '#DEEFFF',
    60: '#1952BB',
    75: '#CEE3FF',
    90: '#1952B',
    100: '#40BAFF',
    125: '#6db0e2',
    150: '#193B79',
    200: '#00A3FF',
    300: '#0094FF',
    400: '#0078CF',
    500: '#0061A7',
  },
  green: {
    50: '#E3FCEF',
    75: '#ABF5D1',
    100: '#79F2C0',
    200: '#57D9A3',
    300: '#36B37E',
    400: '#00875A',
    500: '#006644',
  },
  neutral: {
    0: '#FFFFFF',
    5: '#f6f8fb',
    10: '#FAFAFB',
    20: '#F5F6F7',
    30: '#EBECEF',
    40: '#DEE0E5',
    50: '#BFC4CC',
    60: '#B0B6C0',
    70: '#A3AAB6',
    80: '#949CAA',
    90: '#858E9E',
    100: '#758091',
    200: '#667185',
    300: '#576379',
    400: '#4A576F',
    500: '#3B4963',
    600: '#3B4963',
    700: '#1C2D4A',
    800: '#0D1F3E',
    900: '#001334',
  },
  neutralAlpha: {
    '10A': '#00133405',
    '20A': '#0013340A',
    '30A': '#00133414',
    '40A': '#00133421',
    '50A': '#00133440',
    '60A': '#0013344F',
    '70A': '#0013345C',
    '80A': '#0013346B',
    '90A': '#0013347A',
    '100A': '#0013348A',
    '200A': '#00133499',
    '300A': '#001334A8',
    '400A': '#001334B5',
    '500A': '#001334C4',
    '600A': '#001334D1',
    '700A': '#001334E3',
    '800A': '#001334F2',
    '900A': '#001334',
  },
  purple: {
    50: '#EAE6FF',
    75: '#C0B6F2',
    100: '#998DD9',
    200: '#8777D9',
    300: '#6554C0',
    400: '#5243AA',
    500: '#403294',
  },
  red: {
    50: '#FFEBE6',
    75: '#FFBDAD',
    100: '#FF8F73',
    200: '#FF7452',
    300: '#FF5630',
    400: '#DE350B',
    500: '#BF2600',
  },

  teal: {
    50: '#E6FCFF',
    75: '#B3F5FF',
    100: '#79E2F2',
    200: '#00C7E6',
    300: '#00B8D9',
    400: '#00A3BF',
    500: '#008DA6',
    525: '#4299d9',
  },

  yellow: {
    50: '#FFFAE6',
    75: '#FFF0B3',
    100: '#FFE380',
    200: '#FFC400',
    300: '#FFAB00',
    400: '#FF991F',
    500: '#FF8B00',
  },
}

export const colors = {
  AliceBlue: '#F0F8FF',
  AliceBlue2: '#EFF7FF',
  AlizarinCrimson: '#e02020',
  Alto: '#d5d5d5',
  AthensGray: '#EEF0F4',
  Black: '#000000',
  Boulder: '#7a7a7a',
  Chablis: '#fff4f4',
  Charcoal: '#444444',
  Conifer: '#7FE84A',
  NeonGreen: 'rgba(72, 225, 100, 1)',
  DarkNavy: '#282560',
  Denim2: '#1952BB',
  DodgerBlue2: '#1890FF',
  DustyGrey: '#9b9b9b',
  EarlyDawn: '#FFFBE6',
  Ecstasy: '#F58025',
  FireEngineRed: '#ff0003',
  FrenchGray: '#B9BEC8',
  Gallery: '#eeeeee',
  Gray: '#8b8b8b',
  GrayChateau: '#A0A4A8',
  GreyArea: '#dddddd',
  GulfBlue: '#052257',
  HavelockBlue: '#419BD8',
  LemonChrome: '#ffc303',
  Lavender: '#E3EEFE',
  LilyWhite: '#eefaff',
  Lima: '#52C41A',
  Magritte: '#0099ff',
  Malachite: '#08c06c',
  Malibu: '#75BDFF',
  Matisse: '#1a8598',
  Mercury: '#e9e9e9',
  Nobel: '#b7b7b7',
  OgreOdor: '#fd4c3b',
  PaleBlue: '#cef7ff',
  PattensBlue: '#dae9ff',
  PigeonPost: '#A8BBDE',
  RiverBed: '#424b5f',
  ScienceBlue: '#006ebe',
  SeashellPeach: '#FFF1E8',
  Silver: '#cbcaca',
  Solitude: '#e5f1ff',
  SpanishBlue: '#006CB9',
  SugarCane: '#F6FFED',
  Sun: '#FAAD14',
  TorchRed: '#F5222D',
  TropicalRainForest: '#00684f',
  White: '#FFFFFF',
  WildSand: '#f5f5f5',
  Red: '#FF0000',
  Amber: '#FFC000',
  DavysGrey: '#595959',
  ChineseSilver: '#C9C9C9',
  Orange: '#FF6600',
  ElectricGreen: '#00CC00',
  VividViolet: '#9900FF',
  VividRaspberry: '#FF0066',
  LavenderBlue: '#CCCCFF',
  IslamicGreen: '#00A800',
  SilverLakeBlue: '#5589BF',
  UsafaBlue: '#00479F',
  MaastrichtBlue: '#00132C',
  OxfordBlue: '#002149',
  CeruleanFrost: '#6E94C1',
  LapisLazuli: '#2663AD',
  StarCommandBlue: '#0080BB',
  Bubbles: '#E2F3FC',
  Tosca: '#00C9A7',
  Platinum: '#E3E3E3',
  Arsenic: '#424242',
  VividCerulean: '#0B9EEB',
  RichBlack: '#000913',
  DarkCerulean: '#093D7D',
  MaximumYellowRed: '#F5B73F',
  LightSilver: '#D6D6D6',
  BlackChocolate: '#1E260F45',
  PrussianBlue: '#002656',
  ...palette,
}
