import { useEffect } from 'react'

import Box from '@mui/material/Box'

import Layout from '~/components/Layout/Layout'

import ReloadPrompt from './components/ReloadPrompt'
import { initGA, logPageView } from './utils/analytics'

function App() {
  useEffect(() => {
    initGA()
    logPageView()
  }, [])
  return (
    <Box position="relative">
      <Layout />
      <ReloadPrompt />
    </Box>
  )
}

export default App
