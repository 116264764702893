export const ITH_LOGO = '/images/ith-logo.svg'
export const KADIN_LOGO = '/images/kadin-footer-logo.svg'

export const ERROR_MSG = {
  END_TIME: ({ path }) => `${path} should be higher than start time`,
  REQUIRED: ({ path }) => `${path} is required`,
  REQUIRED_COMMON: (field) => `${field} is required`,
  REQUIRED_OBJECT: ({ path }) =>
    `${path.substring(path.indexOf('.') + 1, path.length)} is required`,
}

export const BASE_URL = process.env.VITE_HOST

export const HEADER_HEIGHT = 90

export const AUTOHIDE_DURATION_SNACKBAR = 5000

export const MESSAGES = {
  ERROR: 'Something went wrong',
  SUCCES: 'Succes',
}

export const PHONE_NUMBER = '+62 819 5899 1689'
export const EMAIL = 'Kadin-ith@kadin.id'
export const INSTAGRAM = 'https://www.instagram.com/kadin_ith/'
export const TIKTOK = 'https://www.tiktok.com/@kadinith'
export const FACEBOOK =
  'https://web.facebook.com/people/Kadin-House/pfbid0hcS9T1eF8pLzgYL8ZB7kDtJW88nWPH5SrMmjtjEt2xmTYUiUgN2C88utWyD9zZFcl/'
export const WHATSAPP = 'https://wa.me/message/CA2PSA6XK2SJO1'
export const YOUTUBE = 'https://www.youtube.com/@KADINITH'

export const IMAGE_BOUNDS = [
  [-84.50156685016026, -294.7297763431533],
  [84.38344875028835, 270.9800229306228],
]

export const MEASUREMENT_ID = process.env.VITE_GOOGLE_ANALYTICS
export const GA_HIT_TYPE = 'Visitor'
