import { makeStyles } from 'tss-react/mui'

import { colors } from '~/styles/colors'
import { fontSize } from '~/styles/theme'

const style = makeStyles()((theme) => ({
  activeMenu: {
    borderBottomColor: colors.Tosca,
    borderBottomStyle: 'solid',
    borderBottomWidth: theme.spacing(1),
  },
  menuBar: {
    alignItems: 'flex-end',
    background: colors.UsafaBlue,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
    padding: theme.spacing(9),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(12),
    },
  },
  menuRoot: {
    color: colors.White,
    cursor: 'pointer',
    fontSize: fontSize[12],
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'none',
  },
}))

export default style
