import ReactGA from 'react-ga4'

import { GA_HIT_TYPE, MEASUREMENT_ID } from '~/config/constants'

export const initGA = () => {
  ReactGA.initialize(MEASUREMENT_ID as string)
}

export const logPageView = () => {
  ReactGA.send({ hitType: GA_HIT_TYPE, page: '/' })
}
