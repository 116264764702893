import { makeStyles } from 'tss-react/mui'

import { colors } from '~/styles/colors'
import { fontSize } from '~/styles/theme'

interface Props {
  open: boolean
}

const style = makeStyles<Props>()((theme, { open }) => ({
  bottomMenu: {
    height: 'calc(100vh - 30rem)',
    overflowX: 'auto',
  },
  container: {
    display: open ? 'block' : 'none',
  },
  listItem: {
    borderRadius: theme.spacing(4),
    color: colors.neutral[0],
    fontSize: fontSize[12],
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
    minWidth: 'max-content',
  },
  listItemActive: {
    color: colors.neutral[0],
    background: `${colors.UsafaBlue} !important`,
  },
  menuBar: {
    background: colors.OxfordBlue,
    display: 'flex',
    flexDirection: 'column',
  },
  topMenu: {
    background: colors.MaastrichtBlue,
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(8),
    height: '100%',
    overflowY: 'auto',
    padding: theme.spacing(12, 10),
  },
}))

export default style
