import React, { memo } from 'react'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

interface Props {
  background?: string
  children?: React.ReactNode
  height?: string | number
  loading: boolean
  loadingComponent?: React.ReactNode
  py?: number | string
}

const Loading = ({
  children,
  height = '',
  loading,
  loadingComponent = null,
  py,
  background = 'transparent',
}: Props) => {
  if (loading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height={height}
        justifyContent="center"
        py={py}
        sx={{ background: background }}
      >
        {loadingComponent || <CircularProgress color="info" />}
      </Box>
    )
  }

  return <>{children}</>
}

export default memo(Loading)
