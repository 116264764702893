import { memo } from 'react'

import Navbar from '~/components/Layout/Layout/Navbar'

/* Will be disable until further notice */
// import useCustom from './hooks'
// import useStyle from './style'

const Navmenu = () => {
  /* Will be disable until further notice */
  // const { classes } = useStyle()
  // const { handleClickSection } = useCustom()

  return (
    <>
      <Navbar
      /* Will be disable until further notice */
      // renderMenu={
      //   <Box display="flex" flexDirection="row" justifyContent="center">
      //     {sections.map((route) => {
      //       return (
      //         <Box
      //           className={classes.button}
      //           id={route.id}
      //           key={route.id}
      //           onClick={() => handleClickSection(route)}
      //         >
      //           <Typography className={classNames(classes.menuRoot, 'text-responsive')}>
      //             {route.label}
      //           </Typography>
      //         </Box>
      //       )
      //     })}
      //   </Box>
      // }
      />
    </>
  )
}

export default memo(Navmenu)
