import dayjs, { Dayjs } from 'dayjs'

const ONE_HOUR = 3600

export const formatDisplayDate = (
  date: string | any,
  format: string,
  utcOffset = 0, // in Hours
) => {
  if (!date) return '-'

  const result = dayjs.utc(date, 'YYYY-MM-DD HH:mm:ss')

  if (utcOffset === 0) return result.local().format(format)

  return result.utcOffset(convertHoursToMinutes(utcOffset)).format(format)
}

export const isDayorNight = (date: any) =>
  dayjs(date).hour() <= 18 && dayjs(date).hour() >= 6 ? 'Day' : 'Night'

export const formatDate = (date: string | any, format: string, toLocal = true) => {
  const d = date === '0001-01-01 00:00:00' || !date ? new Date().toString() : date

  if (toLocal) return dayjs(d).local().format(format)
  return dayjs(d).format(format)
}

export const getUtcFormattedDate = (date, utcOffset) => {
  // Return local time when offset is 0
  if (utcOffset === 0) return dayjs(date).utc().local()

  // Return date using utc offset
  return dayjs(date).utcOffset(convertHoursToMinutes(utcOffset))
}

export const getUnixDateByOffset = (date, utcOffset) => {
  // Return date using utc offset
  const stringDate = dayjs(date).utcOffset(convertHoursToMinutes(utcOffset))
  return dayjs(stringDate).unix()
}

export const getFormattedUnix = (date, utcOffset) => {
  // Return date using utc offset
  const stringDate = dayjs.unix(date)
  return dayjs(stringDate).utcOffset(convertHoursToMinutes(utcOffset))
}

export const formatUtcDateByOffset = (date, offset, format = 'DD/MM/YYYY HH:mm:ss') =>
  dayjs.utc(date).utcOffset(offset).format(format)

export const keepUserDateInput = (date, offset, toISOString = true) => {
  const userOffset = dayjs().utcOffset() / 60
  if (toISOString) {
    return dayjs(date)
      .add(userOffset - offset, 'hour')
      .toISOString()
  }
  return dayjs(date)
    .add(userOffset - offset, 'hour')
    .utc()
    .format('YYYY-MM-DD HH:mm:ss')
}

export const timeZoneChart = (date, offset) => {
  const userOffset = dayjs().utcOffset() / 60
  return dayjs(date).add(offset - userOffset, 'hour')
}

export const timeDiff = (startTime, endtime, utcOffset = 0) => {
  const formattedStartTime = getUtcFormattedDate(startTime, utcOffset)
  const formattedEndTime = getUtcFormattedDate(endtime, utcOffset)

  // Diff date
  const diffMinute = formattedStartTime.diff(formattedEndTime, 'minutes', false)
  const diffDayFloat = formattedStartTime.diff(formattedEndTime, 'days', true)
  const diffSecondsFloat = formattedStartTime.diff(formattedEndTime, 'seconds', true)

  // Format date to total number of hours, minutes, and seconds
  const diffDay = Math.floor(diffDayFloat)
  const hoursFloat = (diffDayFloat - diffDay) * 24
  const hours = Math.floor(hoursFloat)
  const minutes = Math.floor((hoursFloat - hours) * 60)
  const diffMinuteFloat = diffSecondsFloat / 60
  const seconds = Math.floor((diffMinuteFloat - diffMinute) * 60)

  // user friendly spelling date
  const minSpelling = minutes ? (minutes !== 1 ? `${minutes} mins,` : `${minutes} min, `) : ''
  const hourSpelling = hours ? (hours !== 1 ? `${hours} hours,` : `${hours} hour,`) : ''
  const daySpelling = diffDay ? (diffDay !== 1 ? `${diffDay} days,` : `${diffDay} day,`) : ''
  const secSpelling = seconds !== 1 ? `${seconds} seconds` : `${seconds} second`

  return {
    completeSpelling: `${daySpelling} ${hourSpelling} ${minSpelling} ${secSpelling}`,
    daySpelling,
    diffDay,
    diffMinute,
    hourSpelling,
    hours,
    minSpelling,
    minutes,
  }
}

export const formatNumberToTime = (number, type: 'seconds' | 'minutes') =>
  dayjs().startOf('day').add(parseInt(number), type).format('HH:mm:ss')

export const convertHoursToMinutes = (hours) => hours * 60
export const convertMinuteToHours = (minute) => minute / 60

export const getDuration = (number, type: 'seconds' | 'minutes') => {
  if (type === 'seconds') {
    const hours = Math.floor(number / ONE_HOUR)
    const minutes = Math.floor((number % ONE_HOUR) / 60)
    const seconds = Math.floor(number % ONE_HOUR) % 60
    return `${hours} hours ${minutes} minutes ${seconds} seconds`
  }
  const hours = Math.floor(number / 60)
  const minutes = number % 60
  return `${hours} hours ${minutes} minutes`
}

export const getDurationTime = (number, type: 'seconds' | 'minutes') => {
  if (type === 'seconds') {
    const hours = Math.floor(number / ONE_HOUR)
    const minutes = Math.floor((number % ONE_HOUR) / 60)
    const seconds = Math.floor(number % ONE_HOUR) % 60
    return {
      hours,
      minutes,
      seconds,
    }
  }
  const hours = Math.floor(number / 60)
  const minutes = number % 60
  return {
    hours,
    minutes,
  }
}

export const getSimpleDuration = (number, type: 'seconds' | 'minutes') => {
  if (type === 'seconds') {
    const hours = Math.floor(number / ONE_HOUR)
    const minutes = Math.floor((number % ONE_HOUR) / 60)
    return `${hours} h ${minutes} m`
  }
  const hours = Math.floor(number / 60)
  const minutes = number % 60
  return `${hours} h :${minutes} m`
}

export const getSecondsTime = (time: Dayjs) => {
  const h = time.hour() * 3600
  const m = time.minute() * 60
  const s = time.second()

  return {
    h,
    s,
    m,
  }
}
