/* eslint-disable sort-keys */

import { Theme } from '@mui/material/styles'

import { HEADER_HEIGHT } from '~/config/constants'

import { colors } from './colors'

// value         |0px     600px    960px    1280px   1920px
// key           |xs      sm       md       lg       xl
// screen width  |--------|--------|--------|--------|-------->
// range         |   xs   |   sm   |   md   |   lg   |   xl

const globalCss = (theme: Theme) => ({
  '#root': {
    height: '100%',
  },
  '*': {
    fontFamily: 'Poppins',
  },
  '*::-webkit-scrollbar': {
    height: 1,
    marginRight: theme.spacing(3),
    width: 8,
    [theme.breakpoints.down('md')]: {
      height: 0,
    },
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: colors.UsafaBlue,
    borderRadius: theme.spacing(4),
    borderColor: 'none',
    cursor: 'pointer',
  },
  '*::-webkit-scrollbar-track': {
    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  'html, body': {
    margin: 0,
    padding: 0,
    [theme.breakpoints.down('xl')]: {
      [theme.breakpoints.up('lg')]: {
        fontSize: '80%',
      },
    },
  },
  '.hide-on-mobile': {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      'content-visibility': 'none',
    },
  },
  '.wrapper': {
    marginTop: HEADER_HEIGHT,
  },
  '.pointer': {
    cursor: 'pointer',
  },
  '.maps': {
    height: '100vh',
    [theme.breakpoints.down('md')]: {
      height: '40vh',
    },
  },
  '.image': {
    width: '400px !important',
    height: '400px !important',
    img: {
      width: 'inherit',
      height: 'inherit',
      objectFit: 'contain',
    },
  },
})

export default globalCss
