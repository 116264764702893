import { lazy } from '@loadable/component'

const HomePage = lazy(() => import('~/pages/Home'))
const OurPartnersPage = lazy(() => import('~/pages/OurPartners'))
const WorldwidePage = lazy(() => import('~/pages/Worldwide'))
const NewsEventPage = lazy(() => import('~/pages/NewsEvent'))
const AboutUsPage = lazy(() => import('~/pages/AboutUs'))
const ECatalog = lazy(() => import('~/pages/ECatalog'))

import ContactUsPage from '~/pages/ContactUs'

import commercialRoutes from './Commercial'
import newsEventRoutes from './NewsEvent'

export default [
  {
    component: <AboutUsPage />,
    defaultPath: '/about-us',
    dropdownMenu: [],
    id: 'about-us',
    isShow: true,
    nested: null,
    path: '/about-us',
    title: 'About Us',
  },
  {
    component: <HomePage />,
    defaultPath: '/',
    dropdownMenu: [],
    id: 'home',
    nested: null,
    path: '/',
    title: 'Home',
  },
  {
    component: null,
    defaultPath: '/products-of-indonesia',
    dropdownMenu: [],
    id: 'products-of-indonesia',
    nested: commercialRoutes,
    path: '/products-of-indonesia',
    title: 'Products of Indonesia',
  },
  {
    component: null,
    defaultPath: '/our-partner',
    dropdownMenu: [],
    id: 'our-partner',
    nested: null,
    path: '/our-partner',
    title: 'Our Partner',
  },
  {
    component: null,
    defaultPath: '/services',
    dropdownMenu: [],
    id: 'services',
    nested: null,
    path: '/services',
    title: 'Services',
  },
  {
    component: <NewsEventPage />,
    defaultPath: '/news-and-event',
    dropdownMenu: [],
    id: 'news-and-event',
    isShow: true,
    nested: null,
    path: '/news-and-event',
    title: 'News & Event',
  },
  {
    component: <WorldwidePage />,
    defaultPath: '/worldwide',
    dropdownMenu: [],
    id: 'worldwide',
    isShow: true,
    nested: null,
    path: '/worldwide',
    title: 'ITH Worldwide',
  },
  {
    component: <AboutUsPage />,
    defaultPath: '/our-partners',
    dropdownMenu: [],
    id: 'our-partners',
    isShow: true,
    nested: null,
    path: '/our-partners',
    title: 'Our Partners',
  },
  {
    component: <ECatalog />,
    defaultPath: '/e-catalogue',
    dropdownMenu: [],
    id: '/e-catalogue',
    isShow: true,
    nested: null,
    path: '/e-catalogue',
    title: 'E Catalogue',
  },
]

export const sections = [
  {
    id: 'home',
    label: 'Home',
    path: '/',
  },
  {
    id: 'services',
    label: 'Services',
    path: '/',
  },
  {
    id: 'our-partners',
    label: 'Partners',
    path: '/our-partners',
  },
  {
    id: 'worldwide',
    label: 'ITH Worldwide',
    path: '/worldwide',
  },
  {
    id: 'news',
    label: 'News',
    path: '/news-and-event',
  },
  {
    id: 'our-event',
    label: 'Event',
    path: '/news-and-event',
  },
]

export const routes = [
  {
    component: <HomePage />,
    defaultPath: '/',
    dropdownMenu: [],
    id: 'home',
    isShow: true,
    nested: null,
    path: '/',
    title: 'Home',
  },
  {
    component: null,
    defaultPath: '/products-of-indonesia',
    dropdownMenu: [],
    id: 'products-of-indonesia',
    isShow: true,
    nested: commercialRoutes,
    path: '/products-of-indonesia',
    title: 'Commercial Trading',
  },
  {
    component: null,
    defaultPath: '/our-partners',
    dropdownMenu: [],
    id: 'our-partners',
    isShow: false,
    nested: null,
    path: '',
    title: 'Our Partners',
  },
  {
    component: null,
    defaultPath: '/services',
    dropdownMenu: [],
    id: 'services',
    isShow: false,
    nested: null,
    path: '',
    title: 'Services',
  },
  {
    component: null,
    defaultPath: '/news-and-event',
    dropdownMenu: [],
    id: 'news-and-event',
    isShow: true,
    nested: newsEventRoutes,
    path: '/news-and-event',
    title: 'News & Event',
  },
  {
    component: <AboutUsPage />,
    defaultPath: '/about-us',
    dropdownMenu: [],
    id: 'about-us',
    isShow: true,
    nested: null,
    path: '/about-us',
    title: 'About Us',
  },
  {
    component: <NewsEventPage />,
    defaultPath: '/news-and-event',
    dropdownMenu: [],
    id: 'news-and-event',
    nested: null,
    path: '/news-and-event',
    title: 'News & Event',
  },
  {
    component: <ECatalog />,
    defaultPath: '/e-catalogue',
    dropdownMenu: [],
    id: '/e-catalogue',
    nested: null,
    path: '/e-catalogue',
    title: 'E Catalogue',
  },
  {
    component: <WorldwidePage />,
    defaultPath: '/worldwide',
    dropdownMenu: [],
    id: 'worldwide',
    nested: null,
    path: '/worldwide',
    title: 'ITH Worldwide',
  },
  {
    component: <ContactUsPage />,
    defaultPath: '/contact-us',
    dropdownMenu: [],
    id: 'contact-us',
    nested: null,
    path: '/contact-us',
    title: 'contact-us',
  },
  {
    component: <OurPartnersPage />,
    defaultPath: '/our-partners',
    dropdownMenu: [],
    id: 'our-partners',
    nested: null,
    path: '/our-partners',
    title: 'our-partners',
  },
]
