import { memo } from 'react'

import { Box, ListItemButton } from '@mui/material'

import Loading from '~/components/Loading'

import RightMenu from '../../RightMenu'
import useCustom from '../hooks'

import useStyle from './style'

const MenuBarContent = ({ open }) => {
  const { classes } = useStyle({ open })
  const { data, methods } = useCustom()

  if (data.loading) {
    return <Loading loading />
  }
  return (
    <Box className={classes.container}>
      <Box className={classes.menuBar}>
        <Box className={classes.topMenu}>
          {data.leftMenu?.categories?.data?.map((menu) => (
            <ListItemButton
              classes={{
                selected: classes.listItemActive,
              }}
              className={classes.listItem}
              key={menu.id}
              selected={data.selectedMenu.id == menu.id}
              onClick={methods.handleSelectMenu(menu.id)}
            >
              {menu?.attributes?.category_name}
            </ListItemButton>
          ))}
        </Box>
        <Box className={classes.bottomMenu}>
          <RightMenu data={data.rightMenu} />
        </Box>
      </Box>
    </Box>
  )
}

export default memo(MenuBarContent)
