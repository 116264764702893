import { makeStyles } from 'tss-react/mui'

import { colors } from '~/styles/colors'
import { fontSize } from '~/styles/theme'

const style = makeStyles()((theme) => ({
  link: {
    textDecoration: 'none',
  },
  listItem: {
    borderRadius: theme.spacing(4),
    color: colors.neutral[700],
    fontSize: fontSize[18],
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
      color: colors.neutral[0],
      fontSize: fontSize[16],
    },
  },
  listItemButton: {
    color: colors.neutral[700],
    fontSize: fontSize[16],
    fontWeight: theme.typography.fontWeightRegular,
    [theme.breakpoints.down('md')]: {
      color: colors.neutral[0],
      fontSize: fontSize[12],
    },
  },
  rightMenu: {
    height: '100%',
    overflowX: 'auto',
    padding: theme.spacing(4),
    width: '80%',
  },
}))

export default style
