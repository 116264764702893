import { memo } from 'react'

import CallIcon from '@mui/icons-material/Call'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import MailIcon from '@mui/icons-material/Mail'
import YoutubeIcon from '@mui/icons-material/YouTube'
import { Box, Grid, Link, Typography } from '@mui/material'
import classNames from 'classnames'
import { ReactSVG } from 'react-svg'

import { EMAIL, FACEBOOK, INSTAGRAM, PHONE_NUMBER, YOUTUBE } from '~/config/constants'
import { sections } from '~/routes/publicRoute'
import { colors } from '~/styles/colors'

import useCustom from '../../NavMenu/hooks'
import useStyle from '../style'
const Footer = () => {
  const { classes } = useStyle()
  const { handleClickSection } = useCustom()
  return (
    <Box sx={{ backgroundColor: colors.OxfordBlue }}>
      <Box className={classes.container}>
        <Grid container height="100%" spacing={8}>
          <Grid item sm={4.5} xs={12}>
            <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
              <Box className={classes.row}>
                <ReactSVG className={classes.kadinFooterLogo} src="/images/kadin-footer-logo.svg" />
                <ReactSVG className={classes.ithFooterLogo} src="/images/ith-footer-logo.svg" />
              </Box>
              <Typography className={classes.desc}>
                PT Kamar Dagang Indonesia, also known as KADIN Indonesia Trading House is a company
                that specializes in exporting Indonesian goods overseas. Browse our various
                Indonesian product and brands, enriched with their own unique stories, and see how
                our products are made from zero to your doorstep!
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={1.5} xs={12}>
            <Box className={classes.column}>
              <Typography className={classes.title}>Menu</Typography>
              {sections.map((route) => {
                if (route.path !== '/our-partners' && route.path !== '/') {
                  return (
                    <Link className={classes.button} href={route.path} id={route.id} key={route.id}>
                      <Typography className={classNames(classes.menuRoot, 'text-responsive')}>
                        {route.label}
                      </Typography>
                    </Link>
                  )
                }
                return (
                  <Box
                    className={classes.button}
                    id={route.id}
                    key={route.id}
                    onClick={() => handleClickSection(route)}
                  >
                    <Typography className={classNames(classes.menuRoot, 'text-responsive')}>
                      {route.label}
                    </Typography>
                  </Box>
                )
              })}
            </Box>
          </Grid>
          <Grid item sm={3} xs={12}>
            <Box className={classes.column} mb={4}>
              <Typography className={classes.title}>Contact Us</Typography>
              <Box className={classes.row}>
                <Box className={classes.icon}>
                  <MailIcon fontSize="small" />
                </Box>
                <Typography className={classes.desc}>{EMAIL}</Typography>
              </Box>
              <Box className={classes.row}>
                <Box className={classes.icon}>
                  <CallIcon fontSize="small" />
                </Box>
                <Typography className={classes.desc}>{PHONE_NUMBER}</Typography>
              </Box>
            </Box>
            <Box className={classes.column}>
              <Typography className={classes.title}>Main Office</Typography>
              <Typography className={classes.desc}>
                Menara Kadin Indonesia Lt 24Jl. H. R. Rasuna Said Blok X-5 Kav. 2-3Kuningan Timur,
                SetiabudiJakarta Selatan 12950
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={2} xs={12}>
            <Box className={classes.column}>
              <Typography className={classes.title}>Follow Us</Typography>
              <Box className={classes.row}>
                <Link className={classes.icon} href={INSTAGRAM}>
                  <InstagramIcon fontSize="small" />
                </Link>
                <Link className={classes.icon} href={FACEBOOK}>
                  <FacebookIcon fontSize="small" />
                </Link>
                <Link className={classes.icon} href={YOUTUBE}>
                  <YoutubeIcon fontSize="small" />
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Typography className={classNames(classes.desc, classes.absolute)}>
          © 2022 KADIN ITH All rights reserved.
        </Typography>
      </Box>
    </Box>
  )
}

export default memo(Footer)
