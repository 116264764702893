import { makeStyles } from 'tss-react/mui'

const style = makeStyles()((theme) => ({
  image: {
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      height: '100vh',
      width: '100vw',
    },
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100vw',
    },
  },
}))

export default style
