import 'dayjs/locale/id'

import 'regenerator-runtime'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { ApolloProvider } from '@apollo/client'
import CssBaseline from '@mui/material/CssBaseline'
import GlobalStyles from '@mui/material/GlobalStyles'
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import { SnackbarProvider } from 'notistack'
import ReactDOM from 'react-dom/client'

import App from './App'
import globalCss from './styles/global'
import theme from './styles/theme'
import 'leaflet'
import 'leaflet.heat'
import 'leaflet/dist/leaflet.css'
import client from './utils/apollo/client'

dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(timezone)
dayjs.extend(weekday)

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <CssBaseline />
          <GlobalStyles styles={globalCss as any} />
          <SnackbarProvider>
            <ApolloProvider client={client}>
              <LocalizationProvider dateAdapter={AdapterDayjs} locale="id">
                <App />
              </LocalizationProvider>
            </ApolloProvider>
          </SnackbarProvider>
        </StyledEngineProvider>
      </MuiThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
)
