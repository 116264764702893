import { makeStyles } from 'tss-react/mui'

import { colors } from '~/styles/colors'
import { fontSize } from '~/styles/theme'

const imageUrl = '/images/footer.svg'

const style = makeStyles()((theme) => ({
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
  },
  button: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(8),
    },
  },
  container: {
    background: `${colors.BlackChocolate} 27%`,
    backgroundImage: `url(${imageUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '160% 100%',
    minHeight: '70vh',
    padding: theme.spacing(10, 12),
    transformOrigin: 'top right',
  },
  desc: {
    color: colors.neutral[0],
    fontSize: fontSize[18],
    fontWeight: theme.typography.fontWeightRegular,
    [theme.breakpoints.down('md')]: {
      fontSize: fontSize[12],
    },
  },
  icon: {
    background: colors.Bubbles,
    borderRadius: theme.spacing(2),
    color: colors.StarCommandBlue,
    display: 'flex',
    padding: theme.spacing(2),
  },
  ithFooterLogo: {
    display: 'flex',
    div: {
      alignItems: 'center',
      display: 'flex',
      svg: {
        height: 120,
        width: 120,
        [theme.breakpoints.down('md')]: {
          width: 120,
          height: 80,
        },
      },
    },
  },
  kadinFooterLogo: {
    div: {
      svg: {
        height: 120,
        width: 120,
        [theme.breakpoints.down('md')]: {
          width: 80,
          height: 80,
        },
      },
    },
  },
  logo: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5, 0),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  menuRoot: {
    color: colors.White,
    marginRight: theme.spacing(8),
    textDecoration: 'none',
    [theme.breakpoints.down('md')]: {
      fontSize: fontSize[12],
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(5, 4),
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(8),
      flexWrap: 'wrap',
    },
  },
  title: {
    color: colors.neutral[0],
    fontSize: fontSize[18],
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('md')]: {
      fontSize: fontSize[16],
    },
  },
}))

export default style
