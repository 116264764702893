import React, { Suspense } from 'react'
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'

import Box from '@mui/material/Box'

import NotFound from '~/components/Error/NotFound'
import Loading from '~/components/Loading'
import { routes } from '~/routes/publicRoute'


const PublicLayout = () => {
  return (
      <Suspense fallback={
        <Box
          alignItems="center"
          display="flex"
          height="100vh"
          justifyContent="center"
          width="100%"
        >
          <Loading loading py={10} />
        </Box>
      }>
        <Routes>
          {
            routes.map((route) =>  {
              if (route.nested) {
                return (
                  <React.Fragment key={route.id}>
                    <Route element={route.component} key={route.path} path={route.path} />
                    <Route path={route.path}>
                      {route?.nested?.childs.map(({ path, component }) => (
                        <Route element={component} key={path} path={path} />
                      ))}
                    </Route>
                  </React.Fragment>
                )
              }
              return (
                <Route element={route.component} key={route.id} path={route.path}  />
              )
            }
            )
          }
            <Route element={<Navigate to='/'/>} path='/' />
            <Route element={<Navigate to="/404" />} path="*" />
            <Route element={<NotFound />} path="/404" />

        </Routes>
      </Suspense>
    )
}

export default PublicLayout
