import * as Types from '../types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type GetECataloguesQueryVariables = Types.Exact<{
  sort?: Types.Maybe<
    Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>
  >
  filters?: Types.Maybe<Types.ECatalogueFiltersInput>
}>

export type GetECataloguesQuery = {
  eCatalogues?: Types.Maybe<{
    data: Array<
      Pick<Types.ECatalogueEntity, 'id'> & {
        attributes?: Types.Maybe<
          Pick<Types.ECatalogue, 'title' | 'createdAt'> & {
            category?: Types.Maybe<{
              data?: Types.Maybe<{
                attributes?: Types.Maybe<Pick<Types.Category, 'category_name'>>
              }>
            }>
            catalogue?: Types.Maybe<{
              data?: Types.Maybe<{ attributes?: Types.Maybe<Pick<Types.UploadFile, 'url'>> }>
            }>
          }
        >
      }
    >
  }>
}

export type GetCategoriesQueryVariables = Types.Exact<{
  filters?: Types.Maybe<Types.CategoryFiltersInput>
  pagination?: Types.Maybe<Types.PaginationArg>
  subCategory2SPagination2?: Types.Maybe<Types.PaginationArg>
  subCategoriesPagination2?: Types.Maybe<Types.PaginationArg>
}>

export type GetCategoriesQuery = {
  categories?: Types.Maybe<{
    data: Array<
      Pick<Types.CategoryEntity, 'id'> & {
        attributes?: Types.Maybe<
          Pick<Types.Category, 'category_name'> & {
            sub_categories?: Types.Maybe<{
              data: Array<
                Pick<Types.SubCategoryEntity, 'id'> & {
                  attributes?: Types.Maybe<
                    Pick<Types.SubCategory, 'sub_category_name'> & {
                      sub_category_2s?: Types.Maybe<{
                        data: Array<
                          Pick<Types.SubCategory2Entity, 'id'> & {
                            attributes?: Types.Maybe<Pick<Types.SubCategory2, 'sub_category2_name'>>
                          }
                        >
                      }>
                    }
                  >
                }
              >
            }>
          }
        >
      }
    >
  }>
}

export const GetECataloguesDocument = gql`
  query getECatalogues($sort: [String], $filters: ECatalogueFiltersInput) {
    eCatalogues(sort: $sort, filters: $filters) {
      data {
        id
        attributes {
          title
          createdAt
          category {
            data {
              attributes {
                category_name
              }
            }
          }
          catalogue {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetECataloguesQuery__
 *
 * To run a query within a React component, call `useGetECataloguesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetECataloguesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetECataloguesQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetECataloguesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetECataloguesQuery, GetECataloguesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetECataloguesQuery, GetECataloguesQueryVariables>(
    GetECataloguesDocument,
    options,
  )
}
export function useGetECataloguesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetECataloguesQuery, GetECataloguesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetECataloguesQuery, GetECataloguesQueryVariables>(
    GetECataloguesDocument,
    options,
  )
}
export type GetECataloguesQueryHookResult = ReturnType<typeof useGetECataloguesQuery>
export type GetECataloguesLazyQueryHookResult = ReturnType<typeof useGetECataloguesLazyQuery>
export const GetCategoriesDocument = gql`
  query getCategories(
    $filters: CategoryFiltersInput
    $pagination: PaginationArg
    $subCategory2SPagination2: PaginationArg
    $subCategoriesPagination2: PaginationArg
  ) {
    categories(filters: $filters, pagination: $pagination) {
      data {
        id
        attributes {
          category_name
          sub_categories(pagination: $subCategoriesPagination2) {
            data {
              id
              attributes {
                sub_category_name
                sub_category_2s(pagination: $subCategory2SPagination2) {
                  data {
                    id
                    attributes {
                      sub_category2_name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      subCategory2SPagination2: // value for 'subCategory2SPagination2'
 *      subCategoriesPagination2: // value for 'subCategoriesPagination2'
 *   },
 * });
 */
export function useGetCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(
    GetCategoriesDocument,
    options,
  )
}
export function useGetCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(
    GetCategoriesDocument,
    options,
  )
}
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>
