import React from 'react'

import { Box, Link, ListItem, ListItemButton, capitalize } from '@mui/material'
import Grid from '@mui/material/Grid'

import useScreenMobile from '~/utils/hooks/useScreenMobile'

import useStyle from './style'

interface SubCategory {
  attributes: {
    sub_category_2s: {
      data: SubCategory2[]
    }
    sub_category_name: string
  }
  id: string
}

interface SubCategory2 {
  attributes: {
    sub_category2_name: string
  }
  id: string
}

interface RightMenuProps {
  data: SubCategory[]
}

const RightMenu: React.FC<RightMenuProps> = ({ data }) => {
  const { classes } = useStyle()
  const { isMobile } = useScreenMobile()

  const columns: React.ReactNode[] = []
  const itemsPerColumn = 3

  for (let i = 0; i < data.length; i += itemsPerColumn) {
    const columnItems = data.slice(i, i + itemsPerColumn)

    const column = (
      <Grid item key={i} sm={isMobile ? 4 : 2} xs={12}>
        {columnItems.map((menu) => (
          <Box key={menu.id}>
            <ListItem className={classes.listItem}>
              {capitalize(menu.attributes.sub_category_name)}
            </ListItem>
            {menu.attributes.sub_category_2s.data.map((sm) => (
              <Link
                className={classes.link}
                href={`/products-of-indonesia/catalog?category=${menu.attributes.sub_category_name}&sub=${sm.attributes?.sub_category2_name}&subId=${sm.id}`}
                key={sm.attributes.sub_category2_name}
              >
                <ListItemButton className={classes.listItemButton}>
                  {sm.attributes.sub_category2_name}
                </ListItemButton>
              </Link>
            ))}
          </Box>
        ))}
      </Grid>
    )

    columns.push(column)
  }

  return (
    <Grid container spacing={2}>
      {columns}
    </Grid>
  )
}

export default RightMenu
