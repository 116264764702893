import { Box, Link } from '@mui/material'
import classNames from 'classnames'

import menuBar from '~/routes/publicRoute'

import MenuBarContent from '../../MenuBarContent/Desktop'
import useCustom from '../hooks'

import useStyle from './style'

interface Props {
  open: boolean
}

const MenuBar = ({ open }: Props) => {
  const { classes } = useStyle()
  const { data, methods } = useCustom()
  const pathname = `/${window.location.pathname.split('/')[1]}`
  if (!open) {
    return null
  }
  return (
    <Box>
      <Box className={classes.menuBar}>
        {menuBar.map((route) => {
          if (route.nested) {
            return (
              <Link
                className={classNames(classes.menuRoot, {
                  [classes.activeMenu]: route.path === data.selectedMenu,
                })}
                key={route.id}
                onClick={methods.handleSelectMenu(route.path)}
              >
                {route.title}
              </Link>
            )
          }
          return (
            <>
              {route.isShow && (
                <Link
                  className={classNames(classes.menuRoot, {
                    [classes.activeMenu]: route.path === pathname && !data.selectedMenu,
                  })}
                  href={route.path}
                  key={route.id}
                  onClick={methods.handleSelectMenu('')}
                >
                  {route.title}
                </Link>
              )}
            </>
          )
        })}
      </Box>
      <MenuBarContent open={data.openMenubarContent} />
    </Box>
  )
}

export default MenuBar
