import Box from '@mui/material/Box'

import Layout from '~/components/Layout/Public'

import Footer from './Footer'
import NavMenu from './NavMenu'

function App() {
  return (
    <Box position="relative">
      <NavMenu />
      <Layout />
      <Footer />
    </Box>
  )
}

export default App
