import { memo } from 'react'

import MenuIcon from '@mui/icons-material/Menu'
import { Link } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { ReactSVG } from 'react-svg'

import { ITH_LOGO, KADIN_LOGO } from '~/config/constants'
import { colors } from '~/styles/colors'
import { fontSize } from '~/styles/theme'

import MenuBar from '../components/MenuBar'

import useCustom from './hooks'
import useStyle from './style'

// interface Props {
//   renderMenu?: React.ReactNode
// }

const Navbar = (/*{ renderMenu }: Props*/) => {
  const { classes } = useStyle()
  const { data, methods } = useCustom()

  return (
    <AppBar
      sx={{
        background: data.scrollY > 0 || data.firstPath !== '' ? colors.OxfordBlue : 'transparent',
      }}
      className={classes.appBar}
      position="fixed"
    >
      <Box className={classes.contentBar} width="100%">
        <IconButton
          aria-label="burger-menu-btn"
          id="account-navbar-btn"
          sx={{ zIndex: 4 }}
          onClick={methods.handleExpandMenu}
        >
          <Box fontSize={fontSize[40]}>
            <MenuIcon fontSize="medium" htmlColor={colors.White} />
          </Box>
        </IconButton>
        <Box className={classes.logoWrapper}>
          <Link href="/" zIndex={4}>
            <ReactSVG className={classes.kadinLogo} src={KADIN_LOGO} />
          </Link>
          <Link href="/" zIndex={4}>
            <ReactSVG className={classes.logo} src={ITH_LOGO} />
          </Link>
        </Box>
        {/* <Box className={classes.renderMenu} display="flex" flexDirection="row">
          {renderMenu}
        </Box> */}
        <Link className={classes.link} component="a" href="/contact-us">
          Contact Us
        </Link>
      </Box>
      <MenuBar open={data.expandMenu} />
    </AppBar>
  )
}

export default memo(Navbar)
