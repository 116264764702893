import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'

import { GRAPHQL_SERVER_HOST } from '~/config'

const cache = new InMemoryCache()

export const graphqlDefaultOptions = {
  notifyOnNetworkStatusChange: true,
  pollInterval: 1000 * 60 * 30, // 30 Minutes
}

// function under this line is moved to createClient
const queryHttpLink = new HttpLink({
  uri: GRAPHQL_SERVER_HOST,
})

export const client = new ApolloClient({
  cache,
  link: from([queryHttpLink]),
})
// function upper this line is moved to createClient

export const clientUpload = new ApolloClient({
  cache,
  link: from([createUploadLink({ uri: GRAPHQL_SERVER_HOST })]),
})

export default client
