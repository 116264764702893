import { memo } from 'react'

import useScreenMobile from '~/utils/hooks/useScreenMobile'

import FooterDesktop from './Desktop'
import FooterMobile from './Mobile'

const Footer = () => {
  const { isMobile } = useScreenMobile()
  return isMobile ? <FooterMobile /> : <FooterDesktop />
}

export default memo(Footer)
