import { makeStyles } from 'tss-react/mui'

import { colors } from '~/styles/colors'
import { fontSize } from '~/styles/theme'

interface Props {
  open: boolean
}

const style = makeStyles<Props>()((theme, { open }) => ({
  container: {
    display: open ? 'block' : 'none',
  },
  leftMenu: {
    height: '100%',
    overflowY: 'auto',
    padding: theme.spacing(4),
    width: '20%',
  },
  listItem: {
    borderRadius: theme.spacing(4),
    color: colors.neutral[700],
    fontSize: fontSize[18],
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  listItemActive: {
    background: `linear-gradient(0deg, ${colors.Platinum}, ${colors.Platinum}),
linear-gradient(0deg, #F3F4F5, #F3F4F5)`,
    border: `1px solid ${colors.Platinum}`,
  },
  menuBar: {
    background: colors.neutral[0],
    display: 'flex',
    flexDirection: 'row',
    height: '50vh',
  },
  rightMenu: {
    height: '100%',
    overflowX: 'auto',
    padding: theme.spacing(4),
    width: '80%',
  },
}))

export default style
