import { lazy } from '@loadable/component'

const CatalogSearch = lazy(() => import('~/pages/CatalogSearch'))
const DetailProduct = lazy(() => import('~/pages/CatalogSearch/DetailProduct'))
const CompanyProfile = lazy(() => import('~/pages/CatalogSearch/CompanyProfile'))

export default {
  path: 'products-of-indonesia',
  childs: [
    {
      id: '/products-of-indonesia/catalog',
      component: <CatalogSearch />,
      path: '/products-of-indonesia/catalog',
    },
    {
      id: '/products-of-indonesia/catalog/:id',
      component: <DetailProduct />,
      path: '/products-of-indonesia/catalog/:id',
    },
    {
      id: '/products-of-indonesia/company-profile/:id',
      component: <CompanyProfile />,
      path: '/products-of-indonesia/company-profile/:id',
    },
  ],
}
