import { useEffect, useMemo, useState } from 'react'

import { HEADER_HEIGHT } from '~/config/constants'
import useBoolean from '~/utils/hooks/useBoolean'
import useScreenMobile from '~/utils/hooks/useScreenMobile'

const UseCustom = () => {
  const { toggle: handleExpandMenu, value: expandMenu, setFalse } = useBoolean()
  const [scrollY, setScrollY] = useState(0)
  const { isMobile } = useScreenMobile()

  const handleScroll = () => {
    setScrollY(window.scrollY)
  }

  const firstPath = useMemo(() => location.pathname.split('/')[1], [location])

  useEffect(() => {
    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll)

    // Detach the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (isMobile) return
    if (scrollY > HEADER_HEIGHT) {
      setFalse()
    }
    return
  }, [scrollY])

  return {
    data: {
      firstPath,
      scrollY,
      expandMenu,
    },
    methods: {
      handleExpandMenu,
    },
  }
}

export default UseCustom
