import { memo } from 'react'

import useScreenMobile from '~/utils/hooks/useScreenMobile'

import MenuBarDesktop from './Desktop'
import MenuBarMobile from './Mobile'

interface Props {
  open: boolean
}

const MneuBar = ({ open }: Props) => {
  const { isMobile } = useScreenMobile()
  return isMobile ? <MenuBarMobile open={open} /> : <MenuBarDesktop open={open} />
}

export default memo(MneuBar)
