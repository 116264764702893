import { useCallback, useEffect, useState } from 'react'

import { useGetCategoriesLazyQuery } from '~/gqlcodegen/hooks/categories'

import { DEFAULT_MENU } from './helper'
import { ICategories } from './types'

const useCustom = () => {
  const [selectedMenu, setSelectedMenu] = useState(DEFAULT_MENU)
  const [rightMenu, setRightMenu] = useState([])
  const [leftMenu, setLeftMenu] = useState<ICategories>()

  const [getCategories, { loading }] = useGetCategoriesLazyQuery({
    fetchPolicy: 'no-cache',
  })

  const getSubMenus = (menus, id) => {
    const result = menus?.find?.((i) => i.id === id)
    if (result) {
      return result?.attributes?.sub_categories?.data
    }
    return []
  }

  const getMenus = useCallback(() => {
    getCategories({
      variables: {
        pagination: {
          limit: 1000,
        },
        subCategory2SPagination2: {
          limit: 1000,
        },
        subCategoriesPagination2: {
          limit: 1000,
        },
      },
      onCompleted(data) {
        setLeftMenu(data)
        const id = data?.categories?.data?.[0]?.id
        const right = getSubMenus(data?.categories?.data, id)
        setRightMenu(right)
        setSelectedMenu((prev) => ({
          ...prev,
          id: id as string,
        }))
      },
    })
  }, [])

  useEffect(() => {
    const right = getSubMenus(leftMenu?.categories?.data, selectedMenu?.id)
    setRightMenu(right)
  }, [selectedMenu])

  const handleSelectMenu = useCallback(
    (menuIndex) => () => {
      setSelectedMenu((prev) => ({
        ...prev,
        id: menuIndex,
      }))
    },
    [],
  )

  useEffect(() => {
    getMenus()
  }, [])

  return {
    data: {
      leftMenu,
      loading,
      rightMenu,
      selectedMenu,
    },
    methods: {
      handleSelectMenu,
    },
  }
}

export default useCustom
