import { lazy } from '@loadable/component'

const NewsEvent = lazy(() => import('~/pages/NewsEvent'))
const NewsDetail = lazy(() => import('~/pages/NewsEvent/NewsDetail'))
const EventDetail = lazy(() => import('~/pages/NewsEvent/EventDetail'))

export default {
  path: 'news-and-event',
  childs: [
    {
      id: '/news-and-event',
      component: <NewsEvent />,
      path: '/news-and-event',
    },
    {
      id: '/news-and-event/news/:id',
      component: <NewsDetail />,
      path: '/news-and-event/news/:id',
    },
    {
      id: '/news-and-event/event/:id',
      component: <EventDetail />,
      path: '/news-and-event/event/:id',
    },
  ],
}
