import useMediaQuery from '@mui/material/useMediaQuery'

import theme from '~/styles/theme'

const useScreenMobile = () => {
  // Tablet mininum orientation
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const scrollToTop = () => {
    if (isMobile) {
      window.scrollTo({
        top: 0,
      })
    }
    return
  }
  return {
    isMobile,
    scrollToTop,
  }
}

export default useScreenMobile
