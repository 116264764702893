import { makeStyles } from 'tss-react/mui'

import { HEADER_HEIGHT } from '~/config/constants'
import { colors } from '~/styles/colors'
import { fontSize } from '~/styles/theme'

const style = makeStyles()((theme) => ({
  appBar: {
    boxShadow: 'unset',
  },
  contentBar: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    minHeight: HEADER_HEIGHT,
    padding: theme.spacing(0, 6),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
    },
  },
  kadinLogo: {
    div: {
      svg: {
        height: 80,
        width: 50,
        [theme.breakpoints.down('md')]: {
          height: 50,
          width: '70%',
        },
      },
    },
  },
  link: {
    color: colors.neutral[0],
    fontSize: fontSize[18],
    fontWeight: theme.typography.fontWeightBold,
    position: 'absolute',
    right: theme.spacing(4),
    textDecoration: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  logo: {
    div: {
      svg: {
        height: 80,
        width: '50%',
        [theme.breakpoints.down('md')]: {
          height: 50,
          width: '70%',
        },
      },
    },
  },
  logoWrapper: {
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(4),
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(8),
    },
  },
  renderMenu: {
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}))

export default style
