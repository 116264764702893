import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { HEADER_HEIGHT } from '~/config/constants'
import { routes } from '~/routes/publicRoute'
import useBoolean from '~/utils/hooks/useBoolean'

const useCustom = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [currentPath, setPath] = useState('')
  const [open, setOpen] = useState(true)
  const toggleMobileDrawer = useBoolean()

  const firstPath = useMemo(() => location.pathname.split('/')[1], [location])
  const filteredRoutes = useMemo(() => routes.filter((route) => route.path), [])

  const pushHistory = useCallback(
    (path) => {
      navigate(path)

      toggleMobileDrawer.toggle()
    },
    [navigate, toggleMobileDrawer],
  )

  const handleClickSection = (route) => {
    if (firstPath !== '') {
      navigate({
        pathname: route.path,
      })
      return
    }
    // Find the target section
    const sections = document.querySelectorAll('section')

    sections?.forEach((s) => {
      if (s.id === route.id) {
        const rect = s.getBoundingClientRect()
        const topPosition = rect.top + window.scrollY - HEADER_HEIGHT

        window.scrollTo({
          top: topPosition,
          behavior: 'smooth',
        })
      }
    })
  }

  const handleDrawer = useCallback(() => {
    setOpen(!open)
  }, [open])

  useEffect(() => {
    setPath(location.pathname)
  }, [location])

  return {
    currentPath,
    filteredRoutes,
    firstPath,
    handleClickSection,
    handleDrawer,
    pushHistory,
    toggleMobileDrawer,
  }
}

export default useCustom
