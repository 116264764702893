import { makeStyles } from 'tss-react/mui'

import { colors } from '~/styles/colors'
import { fontSize } from '~/styles/theme'

const style = makeStyles()((theme) => ({
  activeMenu: {
    borderBottomColor: colors.Tosca,
    borderBottomStyle: 'solid',
    borderBottomWidth: theme.spacing(1),
  },
  menuBar: {
    alignItems: 'center',
    background: colors.UsafaBlue,
    display: 'flex',
    flexDirection: 'row',
    height: 80,
    padding: theme.spacing(0, 6, 0, 6),
  },
  menuRoot: {
    color: colors.White,
    cursor: 'pointer',
    fontSize: fontSize[16],
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(8),
    textDecoration: 'none',
  },
}))

export default style
