export const GRAPHQL_SERVER_HOST = `${process.env.VITE_GRAPHQL_HOST}/graphql`

export const host = process.env.VITE_HOST

export const mapsApiKey = process.env.VITE_MAPS_API_KEY

export const assetHostUrl = `${process.env.VITE_S3_DISTRIBUTION}/${process.env.VITE_S3_ENV}`
export const assetGroupLogoUrl = `${process.env.VITE_S3_DISTRIBUTION}/${process.env.VITE_S3_ENV}/group_logo`

export const mapUrl = `https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}?token=${mapsApiKey}`
export const mapAttr =
  'Tiles &copy; Esri &mdash; Source: Esri <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'

export const graphqlDefaultOptions = {
  notifyOnNetworkStatusChange: true,
  pollInterval: 1000 * 60 * 5, // 5 Minutes
}
